<template>
  <v-container :fill-height="!permissionToRegister">
    <!-- check packageID form -->
    <v-row align="center" justify="center" v-if="!permissionToRegister">
      <v-col cols="12" sm="8" lg="6" xl="5">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <h1 class="title">
              Register Your Charity Getaways Travel Package
            </h1>
            <p class="body-1 mt-4">
              Please copy and paste your package ID below.
            </p>
            <p class="body-1">
              Your package ID can be found in the email you received from your
              charity/organization.
            </p>
            <p class="font-italic">
              Please note you can click directly on the link included in the
              email you received from your charity/organization to skip this
              step.
            </p>
          </v-card-text>
          <v-card-text>
            <v-form ref="checkPackageIDForm" @submit.prevent="onCheckPackageID">
              <v-text-field
                v-model.trim="packageID"
                hint="Copy and paste your package ID here"
                outlined
                label="Package ID"
                :loading="loading"
                :disabled="loading || packageEligibleToRegister"
                :rules="[textRules.required]"
              ></v-text-field>
            </v-form>
            <!-- alerts -->
            <!-- packageAlreadyRegistered -->
            <v-alert
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="packageAlreadyRegistered"
            >
              This package has already been registered. <br />
              <a href="/sign-in" class="font-weight-medium mt-4 d-block"
                >Please click here to sign in.</a
              >
              <!-- <br />
              If you need help,
              <router-link to="/contact" class="font-weight-medium"
                >please click here to contact us.</router-link
              > -->
            </v-alert>
            <!-- packageAlreadyRegistered -->
            <!-- packageNotFound -->
            <v-alert
              outlined
              type="error"
              icon="mdi-alert-outline"
              v-if="packageNotFound"
            >
              We were unable to find that package within our system. Please
              ensure you've entered the package ID correctly.<br />
              If you need help,
              <router-link to="/contact" class="font-weight-medium"
                >please click here to contact us.
              </router-link>
            </v-alert>
            <!-- packageNotFound -->
            <!-- packageEligibleToRegister -->
            <v-alert
              outlined
              type="success"
              icon="mdi-check-circle-outline"
              v-if="packageEligibleToRegister"
            >
              Success! Your package is eligible for registration. Please wait
              while we set up your registration page. Thank you.
            </v-alert>
            <!-- packageEligibleToRegister -->
            <!-- alerts -->
          </v-card-text>
          <v-card-actions class="d-flex flex-column flex-sm-row">
            <v-btn
              outlined
              color="primary"
              @click="$router.push('/')"
              :loading="loading"
              :disabled="loading || packageEligibleToRegister"
              :block="$vuetify.breakpoint.xs"
              class="my-2 my-sm-0"
              >Back Home</v-btn
            >
            <v-spacer />
            <v-btn
              id="submitButton"
              depressed
              color="primary"
              @click="onCheckPackageID"
              :block="$vuetify.breakpoint.xs"
              class="my-2 my-sm-0"
              :loading="loading"
              :disabled="loading || packageEligibleToRegister"
              >Submit Package ID</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="permissionToRegister">
      <!-- -->
      <!-- toolbar -->
      <v-col cols="12">
        <v-toolbar dense color="primary" dark flat rounded>
          <v-toolbar-title>Register Your Package</v-toolbar-title>
          <v-spacer />
          <!-- home btn -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$router.push('/')"
              >
                <v-icon>mdi-home-outline</v-icon>
              </v-btn>
            </template>
            <span>Home</span>
          </v-tooltip>
          <!-- home  -->
          <!-- dark mode button -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                depressed
                dark
                v-bind="attrs"
                v-on="on"
                @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              >
                <v-icon>{{
                  $vuetify.theme.dark ? "mdi-brightness-4" : "mdi-brightness-6"
                }}</v-icon>
              </v-btn>
            </template>
            <span>Dark Mode</span>
          </v-tooltip>
          <!-- dark mode button -->
        </v-toolbar>
      </v-col>
      <!-- toolbar -->

      <!-- email address information -->
      <v-col cols="12" md="6" class="d-md-flex order-2 order-md-1"
        ><v-card outlined :loading="loading">
          <v-card-text class="headline font-weight-medium text--primary">
            Important Email Address Information
          </v-card-text>
          <v-card-text class="body-1 text--primary mb-0 pb-2">
            <p>
              Your <strong>email address</strong> will be your
              <strong>login credentials</strong> and your
              <strong>primary point of contact</strong>
              with Charity Getaways.
            </p>
            <p>
              <strong
                >All Charity Getaways communications will be delivered to your
                email address.</strong
              >
            </p>
            <p><strong>Please ensure that your email address:</strong></p>
            <p>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Is valid and working
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Is always accessible to you
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Is <strong>NOT</strong> your work email address
              </span>
              <span class="d-block my-1">
                <v-icon left small color="green">mdi-check</v-icon>
                Is <strong>NOT</strong> an extra email address that you rarely
                use or rarely check
              </span>
            </p>
            <p class="pt-2">Thank you &amp; Happy Travels!</p>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- email address information -->

      <!-- explainer video -->
      <v-col cols="12" md="6" class="d-md-flex order-1 order-md-2">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary title font-weight-medium">
            Please click on the video for a detailed explanation of how to
            register your package</v-card-text
          >
          <v-card-text>
            <iframe
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/5t1r5eHyaLw"
              title="Register Your Package"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- explainer video -->

      <!-- form -->
      <v-col cols="12" md="8" class="order-4 order-md-3">
        <v-card outlined class="d-md-flex flex-md-column">
          <v-card-text class="display-1 text--primary"
            >Register Your Package</v-card-text
          >
          <v-card-subtitle class="pt-2 text--primary">
            Please complete the form below to register your package
          </v-card-subtitle>
          <v-card-text class="text--primary" :loading="loading">
            <v-form ref="registrationForm" @submit.prevent="onRegisterPackage">
              <v-row>
                <!-- terms -->
                <v-col cols="12">
                  <p>
                    <span class="d-block title">Terms &amp; Conditions</span>
                    <span class="d-block body-1 pt-1"
                      >Please review the terms &amp; conditions of your package
                      before proceeding with registration.</span
                    >
                  </p>
                  <v-alert
                    class="scrollBox"
                    style="
                      overflow-y: scroll;
                      max-height: 300px;
                      color: #1976d2;
                      border: thin solid #1976d2;
                    "
                  >
                    <div v-html="packageTerms.content" />
                  </v-alert>

                  <v-alert type="info" outlined :icon="false">
                    <v-checkbox
                      v-model="packageTerms.agreed"
                      :loading="loading"
                      :disabled="loading || packageAlreadyRegistered"
                      color="primary"
                      label="I have reviewed and agree to the terms and conditions of my package"
                      :rules="[checkboxRules.required]"
                    ></v-checkbox>
                  </v-alert>
                </v-col>
                <!-- terms -->

                <!-- first name -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="First Name *"
                    v-model.trim="firstName"
                    required
                    :rules="[(v) => !!v || 'Field Required']"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                    hint="Name listed must be present at check in"
                  ></v-text-field>
                </v-col>
                <!-- first name -->
                <!-- last name -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Last Name *"
                    persistent-hint
                    hint="Name listed must be present at check in"
                    v-model.trim="lastName"
                    required
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                    :rules="[textRules.required]"
                  ></v-text-field>
                </v-col>
                <!-- last name -->
                <!-- phone -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    @keypress="limitPhoneValues($event)"
                    label="Phone *"
                    v-model.trim="phone"
                    required
                    persistent-hint
                    type="number"
                    hint="Please enter numbers only"
                    :rules="[
                      textRules.required,
                      phone ? phoneRules.tenNumbers : '',
                    ]"
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  ></v-text-field>
                </v-col>
                <!-- phone -->
                <!-- email -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="email"
                    @input="email = email.toLowerCase()"
                    label="Email *"
                    type="email"
                    persistent-hint
                    hint="Important: your email will receive all SAV communications and must be valid and accessible to you at all times"
                    required
                    :rules="[textRules.required, emailRules.valid]"
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  ></v-text-field>
                </v-col>
                <!-- email -->
                <!-- secondary email -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="secondaryEmail"
                    @input="secondaryEmail = secondaryEmail.toLowerCase()"
                    hint="Add a secondary email address to receive copies of all SAV communications sent to your primary email address"
                    label="Secondary Email (Optional)"
                    type="email"
                    persistent-hint
                    :rules="[emailRules.secondary]"
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  ></v-text-field>
                </v-col>
                <!-- secondary email -->
                <v-col cols="12" class="my-2">
                  <v-divider />
                </v-col>
                <!-- Mailing Address -->
                <v-card-text class="d-block title"
                  >Mailing Address
                </v-card-text>
                <v-col cols="12">
                  <v-text-field
                    label="Address 1 *"
                    v-model.trim="address1"
                    required
                    :rules="[(v) => !!v || 'Field Required']"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Address 2"
                    v-model.trim="address2"
                    persistent-hint
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  ></v-text-field>
                </v-col>

                <v-row>
                  <v-col cols="12" :sm="requiresState ? 3 : 4">
                    <v-select
                      label="Country *"
                      v-model="country"
                      :items="countryList"
                      required
                      :rules="[(v) => !!v || 'Field Required']"
                      persistent-hint
                      :loading="loading"
                      :disabled="loading || packageAlreadyRegistered"
                      @change="checkStateRequirement"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="City *"
                      v-model.trim="city"
                      required
                      :rules="[(v) => !!v || 'Field Required']"
                      persistent-hint
                      :loading="loading"
                      :disabled="loading || packageAlreadyRegistered"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" v-if="requiresState">
                    <v-text-field
                      label="State *"
                      v-model.trim="state"
                      required
                      :rules="[(v) => !!v || 'Field Required']"
                      persistent-hint
                      :loading="loading"
                      :disabled="loading || packageAlreadyRegistered"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" :sm="requiresState ? 3 : 4">
                    <v-text-field
                      label="Zip Code *"
                      v-model.trim="zipCode"
                      required
                      :rules="[(v) => !!v || 'Field Required']"
                      persistent-hint
                      :loading="loading"
                      :disabled="loading || packageAlreadyRegistered"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Mailing Address -->

                <v-col cols="12" class="my-2">
                  <v-divider />
                </v-col>

                <!-- errors -->
                <v-col cols="12" class="my-2">
                  <!-- form validation error -->
                  <v-alert
                    outlined
                    type="error"
                    icon="mdi-alert-outline"
                    v-if="registrationFormValidationError"
                  >
                    Please check the form for errors and completed all required
                    fields
                  </v-alert>
                  <!-- form validation error -->
                  <!-- package is already registered -->
                  <v-alert
                    outlined
                    type="error"
                    icon="mdi-alert-outline"
                    v-if="packageAlreadyRegistered"
                  >
                    This package has already been registered.<br />
                    <router-link to="/contact" class="font-weight-medium"
                      >Please click here to sign in.</router-link
                    ><br /><br />
                    If you need help,
                    <router-link to="/contact" class="font-weight-medium"
                      >please click here to contact us.</router-link
                    >
                    <br />
                    Thank you.
                  </v-alert>
                  <!-- package is already registered -->
                  <!-- auth account already exists -->
                  <v-alert
                    outlined
                    type="error"
                    icon="mdi-alert-outline"
                    v-if="emailAlreadyUsedToCreateAccount"
                  >
                    This primary email has already been assigned to a package.
                    Please enter a different primary email.
                  </v-alert>
                  <!-- auth account already exists -->
                </v-col>
                <!-- errors -->

                <v-col cols="12" class="d-flex flex-column flex-sm-row">
                  <!-- cancel button -->
                  <v-btn
                    color="error"
                    depressed
                    outlined
                    @click="$router.push('/')"
                    class="mr-sm-4 my-2 my-sm-0"
                    :loading="loading"
                    :disabled="loading"
                    >Cancel</v-btn
                  >
                  <!--  help button -->
                  <v-btn
                    color="primary"
                    depressed
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    class="my-2 my-sm-0"
                    @click="helpDialog = true"
                    >I need help</v-btn
                  >

                  <v-spacer />
                  <!-- register button -->
                  <v-btn
                    color="primary"
                    depressed
                    @click.prevent="onRegisterPackage"
                    class="my-2 my-sm-0"
                    :loading="loading"
                    :disabled="loading || packageAlreadyRegistered"
                  >
                    Register Your Package</v-btn
                  >
                </v-col>
                <!-- submit button -->
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- form -->

      <!-- your information -->
      <v-col cols="12" md="4" class="order-3 order-md-4">
        <v-card outlined>
          <v-card-text class="text--primary">
            <h3 class="headline mb-2">Your Information</h3>
            <v-list>
              <!-- package code -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Package Code</v-list-item-title>
                  <v-list-item-subtitle class="text-uppercase">{{
                    specificWinner.packageCode
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- package code -->
              <!-- destination -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Destination</v-list-item-title>
                  <v-list-item-subtitle>{{
                    specificWinner.destination
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- destination -->
              <!-- number of nights -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Number of Nights</v-list-item-title>
                  <v-list-item-subtitle>{{
                    specificWinner.numberNights
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- number of nights -->
              <!-- room type -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Room Type</v-list-item-title>
                  <v-list-item-subtitle>{{
                    specificWinner.unitType
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- room type -->
              <!-- event name -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Event Name</v-list-item-title>
                  <v-list-item-subtitle>{{
                    eventReport.eventName
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <!-- event name -->
              <!-- event date -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Event Date</v-list-item-title>
                  <v-list-item-subtitle>{{
                    formatDate(eventReport.eventDate)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- event date -->

              <!-- travel by date -->
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Travel By Date</v-list-item-title>
                  <v-list-item-subtitle>{{
                    formatDate(specificWinner.travelByDate)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- travel by date -->
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- your information -->

      <!-- helpDialog -->
      <v-dialog v-model="helpDialog" width="500">
        <v-card :loading="loading">
          <v-card-text class="text--primary pt-6">
            <h3 class="mb-6">
              Please watch the video below for a detailed explanation of how to
              register your package
            </h3>
            <iframe
              class="elevation-1"
              width="100%"
              height="315"
              src="https://www.youtube.com/embed/5t1r5eHyaLw"
              title="Register Your Package"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" text @click="helpDialog = false"
              >Close</v-btn
            >
            <!-- <v-btn color="primary" text to="/contact"> Contact Us </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- helpDialog -->
      <!--  -->
    </v-row>

    <!-- packageAlreadyRegistered dialog -->
    <v-dialog v-model="packageAlreadyRegisteredDialog" width="500">
      <v-card>
        <v-card-title class="text-h6">
          <v-icon color="error" left>mdi-alert-outline</v-icon>This package has
          already been registered
        </v-card-title>

        <v-card-text class="body-1 text--primary mt-5">
          <p>
            <strong>This travel package has already been registered.</strong>
          </p>

          <p>
            Please
            <strong> <a href="/sign-in">click here to sign in</a> </strong>
            with the email used to register the package.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" depressed href="/sign-in">
            Sign In
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="packageAlreadyRegisteredDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "@/plugins/firebase";
import axios from "axios";
import formRules from "@/mixins/formRules";
import formatDate from "@/mixins/formatDate";
import limitPhoneValues from "@/mixins/limitPhoneValues";
import moment from "moment";
export default {
  mixins: [formRules, formatDate, limitPhoneValues],
  mounted() {
    this.getPackageIDfromURL();
  },
  data: () => ({
    helpDialog: false,
    packageID: "",
    packageAlreadyRegistered: false,
    packageAlreadyRegisteredDialog: false,
    packageNotFound: false,
    packageEligibleToRegister: false,
    permissionToRegister: false,
    registrationFormValidationError: false,
    emailAlreadyUsedToCreateAccount: false,
    success: false,
    eventReport: "",
    specificWinner: "",
    packageTerms: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    secondaryEmail: "",
    lastEventReport: "",
    bookingsConfirmations: "",
    mps: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryList: [
      "United States",
      "Canada",
      "Mexico",
      "United Kingdom",
      "Germany",
      "France",
      "Australia",
      "India",
      "China",
      "Japan",
      "Brazil",
      "South Africa",
      "Italy",
      "Spain",
      "Netherlands",
      "Sweden",
      "Norway",
      "Denmark",
      "Finland",
      "New Zealand",
      "Argentina",
      "Colombia",
      "Chile",
      "Peru",
      "Switzerland",
    ],
    requiresState: false,
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    getPackageIDfromURL() {
      // sets this.packageID if found
      // simulates click on submit button
      let url = window.location.href;
      // check if url contains "?"
      if (url.includes("?")) {
        // split again at "=" to isolate packageID
        let packageID = url.split("?")[1].split("=")[1];
        if (packageID) {
          this.packageID = packageID.replace(" ", "").trim();
          setTimeout(() => {
            var elem = document.getElementById("submitButton");
            elem.click();
          }, 500);
        }
      }
    },
    async onCheckPackageID() {
      if (this.$refs.checkPackageIDForm.validate()) {
        await this.$store.dispatch("setLoading", true);

        // find eventReport containing packageID
        let eventReportsRef = firebase
          .firestore()
          .collection("eventReports")
          .where("winnerPackageIDs", "array-contains", this.packageID);
        let eventReportDocs = await eventReportsRef.get();

        // if no event report containing packageID
        // if database error where 2 event reports contain the same packageID
        if (!eventReportDocs.docs.length || eventReportDocs.docs.length > 1) {
          this.packageNotFound = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // isolate specific eventReport
        let eventReportsData = eventReportDocs.docs.map((i) => i.data())[0];

        // bind local eventReport
        await this.$bind(
          "eventReport",
          firebase
            .firestore()
            .collection("eventReports")
            .doc(eventReportsData.id)
        );

        // isolate specificWinner
        this.specificWinner = this.eventReport.winners.filter(
          (i) => i.packageID == this.packageID
        )[0];

        // if package already registered
        if (this.specificWinner.isRegistered) {
          this.packageAlreadyRegistered = true;
          this.packageAlreadyRegisteredDialog = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // find package containing terms and conditions
        let allPackagesRef = firebase
          .firestore()
          .collection("retailPackages")
          .doc(this.specificWinner.packageCode);
        let packageDoc = await allPackagesRef.get();

        // if package not found in DB
        if (!packageDoc.exists) {
          this.packageNotFound = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // set local terms
        this.packageTerms = packageDoc.data().terms;

        // all criteria met => eligible for registration
        await this.$store.dispatch("setLoading", false);
        this.packageEligibleToRegister = true;
        setTimeout(() => {
          this.permissionToRegister = true;
        }, 3000);

        return;
      }
    },
    async onRegisterPackage() {
      if (this.$refs.registrationForm.validate()) {
        await this.$store.dispatch("setLoading", true);
        this.registrationFormValidationError = false;
        // await this.$store.dispatch("setLoading", true);

        // isolate specificWinner
        let specificWinner = this.eventReport.winners.filter(
          (i) => i.packageID == this.packageID
        )[0];

        // if package already registered
        if (specificWinner.isRegistered) {
          this.packageAlreadyRegistered = true;
          await this.$store.dispatch("setLoading", false);
          return;
        }

        // checking if email already contained within userAccount in firestore
        let userAccountDocRef = firebase
          .firestore()
          .collection("userAccounts")
          .where("email", "==", this.email);
        let userAccountDocs = await userAccountDocRef.get();

        let isExistingUser = false;
        // if email already contained within userAccount in firestore
        if (userAccountDocs.docs.length) {
          // this.emailAlreadyUsedToCreateAccount = true;
          // await this.$store.dispatch("setLoading", false);
          // return;
          isExistingUser = true;
        }

        // Check the last travel by date is exipre or not
        if (isExistingUser) {
          let userAccounts = userAccountDocs.docs.map((i) => i.data());

          // filter the latest record
          const sortedUserAccount = userAccounts.sort((a, b) =>
            moment(b.dateRegistered).diff(moment(a.dateRegistered))
          )[0];

          // Bookings Confirmations
          await this.$bind(
            "bookingsConfirmations",
            firebase
              .firestore()
              .collection("bookingsConfirmations")
              .where("packageID", "==", sortedUserAccount.packageID)
              .where("email", "==", sortedUserAccount.email)
              .where("archived", "==", false)
          );

          // Event Report
          await this.$bind(
            "lastEventReport",
            firebase
              .firestore()
              .collection("eventReports")
              .doc(sortedUserAccount.eventReportID)
          );

          let currentDate = moment(); // Get the current date

          // check the latest record travel by date is exipred or not
          // const isDateBeforeCurrentDate = moment(this.lastEventReport.travelByDate, "YYYY-MM-DD").isBefore(currentDate, 'day');

          // if not expired then return
          // if (!isDateBeforeCurrentDate) {
          //    await this.$store.dispatch("setLoading", false);
          //   return;
          // }

          if (this.bookingsConfirmations.length) {
            const isCheckInDateBeforeCurrentDate = moment(
              this.bookingsConfirmations[0].checkInDate,
              "YYYY-MM-DD"
            )
              .add(14, "days")
              .isBefore(currentDate, "day");
            // const isCheckInDateBeforeCurrentDate = moment(this.bookingsConfirmations[0].checkInDate, "YYYY-MM-DD").isBefore(currentDate, 'day');

            if (!isCheckInDateBeforeCurrentDate) {
              this.emailAlreadyUsedToCreateAccount = true;
              await this.$store.dispatch("setLoading", false);
              return;
            }
          } else {
            await this.$bind(
              "mps",
              firebase
                .firestore()
                .collection("mps")
                .where("puid", "==", sortedUserAccount.packageID)
            );

            if (this.mps.length && this.mps[0].issueDate) {
              const issueDate = moment(
                this.mps[0].issueDate.replaceAll("/", "-"),
                "MM-DD-YYYY"
              ); // Replace with your specific date

              // Add 3 years and 14 days to the initial date
              // const futureDate = issueDate.add(3, 'years').add(14, 'days');
              const futureDate = this.mps[0].originalExpiryDate
                ? moment(
                    this.mps[0].originalExpiryDate.replaceAll("/", "-"),
                    "MM-DD-YYYY"
                  ).add(14, "days")
                : issueDate.add(3, "years").add(14, "days");
              const isBefore = futureDate.isBefore(currentDate, "day");
              if (!isBefore) {
                this.emailAlreadyUsedToCreateAccount = true;
                await this.$store.dispatch("setLoading", false);
                return;
              }
            }
          }
        }

        // checking if email already used to create auth account
        // let signInMethods = await firebase
        //   .auth()
        //   .fetchSignInMethodsForEmail(this.email);

        // // auth account exists
        // if (signInMethods.length) {
        //   this.emailAlreadyUsedToCreateAccount = true;
        //   await this.$store.dispatch("setLoading", false);
        //   return;
        // }

        // create auth user
        let newUser = {};
        if (!isExistingUser) {
          newUser = await firebase
            .auth()
            .createUserWithEmailAndPassword(
              this.email,
              "$6Rp94^1Q*riax9!l*0R1#tnYr&a2BXPNXh@Szab7UBE$tomIuMaO*bVsa!fHNd%"
            );
        } else {
          newUser = await firebase
            .auth()
            .signInWithEmailAndPassword(
              this.email,
              "$6Rp94^1Q*riax9!l*0R1#tnYr&a2BXPNXh@Szab7UBE$tomIuMaO*bVsa!fHNd%"
            );
        }

        newUser = newUser.user;

        // firestore userAccount data
        let userAccountData = {
          avSheets: [],
          canAccess: true,
          canBook: true,
          canBrowseInventory: true,
          canSendMessages: true,
          confirmationNumber: "",
          dateConfirmed: "",
          dateInventorySelected: "",
          dateRegistered: new Date().toISOString().substring(0, 10),
          dateUpdated: "",
          email: this.email,
          eventReportID: this.eventReport.id,
          firstName: this.firstName,
          hasSelectedInventory: false,
          id: userAccountDocs.docs.length
            ? `${newUser.uid}+${userAccountDocs.docs.length}`
            : newUser.uid,
          inventorySelections: [],
          isConfirmed: false,
          lastName: this.lastName,
          lastSignedIn: "",
          mailingAddress: {
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            country: this.country,
            state: this.state,
            zipCode: this.zipCode,
          },
          newDashboardAVSheetNotification: false,
          newDashboardConfirmationNotification: false,
          newDashboardMessageNotification: true,
          numberOfAVSheets: 0,
          numberOfInventorySelections: 0,
          pendingAVSheetCreation: false,
          pendingConfirmation: false,
          phone: this.phone,
          packageID: this.packageID,
          secondaryEmail: this.secondaryEmail ? this.secondaryEmail : "",
          source: "sav traveler portal",
          termsAndConditions: this.packageTerms,
          timeline: [
            {
              adminActionRequired: false,
              archived: false,
              dateCreated: new Date().toISOString().substring(0, 10),
              from: "admin",
              message: "",
              readByAdmin: true,
              repliedToByAdmin: true,
              senderActionRequired: false,
              title: "You registered your package",
              to: "",
              icon: "mdi-checkbox-marked-circle-outline",
              color: "primary",
            },
          ],
        };

        if (!isExistingUser) {
          //  create travelUserAccount
          const travelerUserAccounts = await firebase
            .firestore()
            .collection("travelerUserAccounts")
            .doc(newUser.uid)
            .set({
              accounts: [userAccountData.id],
            });
        } else {
          // check user has travelerUserAccount collection in DB
          const travelerUserAccountsDoc = await firebase
            .firestore()
            .collection("travelerUserAccounts")
            .doc(newUser.uid)
            .get();

          const travelerUserAccounts = travelerUserAccountsDoc.data();
          console.log("travelerUserAccounts", travelerUserAccounts);
          if (travelerUserAccountsDoc.exists) {
            // new userAccount push in travelerUserAccount
            await firebase
              .firestore()
              .collection("travelerUserAccounts")
              .doc(newUser.uid)
              .update({
                accounts: [
                  ...travelerUserAccounts.accounts,
                  userAccountData.id,
                ],
              });
          } else {
            //existing user and new user push in travelerUserAccount
            await firebase
              .firestore()
              .collection("travelerUserAccounts")
              .doc(newUser.uid)
              .set({
                accounts: [newUser.uid, userAccountData.id],
              });
          }
        }

        // creating userAccount in firestore
        await firebase
          .firestore()
          .collection("userAccounts")
          .doc(userAccountData.id)
          .set(userAccountData);

        // update eventReport winner
        this.specificWinner.isRegistered = true;
        await firebase
          .firestore()
          .collection("eventReports")
          .doc(this.eventReport.id)
          .update({
            winners: this.eventReport.winners,
          });

        let emailData = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          secondaryEmail: this.secondaryEmail ? this.secondaryEmail : "",
          phone: this.phone,
          destination: this.specificWinner.destination,
          numberNights: this.specificWinner.numberNights,
          packageCode: this.specificWinner.packageCode,
          eventName: this.eventReport.eventName,
          eventDate: this.eventReport.eventDate,
          packageID: this.packageID,
          dateRegistered: userAccountData.dateRegistered,
        };

        // send emails
        await axios.post("/api/new-user-registration", {
          emailData: emailData,
        });

        // set vuex userID
        await this.$store.dispatch("setUserID", userAccountData.id);

        // add admin notification
        await firebase
          .firestore()
          .collection("adminNotifications")
          .add({
            archived: false,
            dateAdded: new Date().toISOString().substring(0, 10),
            dateArchived: "",
            text: `[New Package Registration - Traveler Portal] Name: ${this.firstName} ${this.lastName}, Email: ${this.email}`,
          });

        // success
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "success",
          text: "Package Successfully Registered",
          timeout: 5000,
        });

        // route to dashboard
        this.$router.push("/dashboard");

        return;
      }
      this.registrationFormValidationError = true;
      await this.$store.dispatch("setLoading", false);
      return;
    },
    checkStateRequirement() {
      const countriesWithStates = [
        "United States",
        "Canada",
        "Mexico",
        "Australia",
        "India",
        "Brazil",
      ];
      this.requiresState = countriesWithStates.includes(this.country);
    },
  },
};
</script>
